import createSagaMiddleware from 'redux-saga';
import { createStore, compose, applyMiddleware } from 'redux';

import reducer from '../reducer';
import initialState from '../store/initialState';
import rootSaga from '../saga';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    reducer,
    initialState,
    compose(
        applyMiddleware(sagaMiddleware)
    )
);

sagaMiddleware.run(rootSaga);
export default store;

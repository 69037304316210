import { takeLatest } from 'redux-saga/effects';

import { FETCH_VIEW_SETTINGS_REQUEST } from '../viewSettingsConstants';
import fetchViewSettings from '../sagas/fetchViewSettings';

function* viewSettingsSagas() {
    yield takeLatest(FETCH_VIEW_SETTINGS_REQUEST, fetchViewSettings);
}

export default viewSettingsSagas;

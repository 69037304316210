import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';

import HeaderContent from '../../modules/WallPreview/HeaderContent';
import PreviewContainer from '../../modules/WallPreview/PreviewContainer';
import OverlayContent from '../WallPreview/OverlayContent';
import { fetchViewSettingsRequest } from '../WallPreview/viewSettingsAction';
import BlockUI from '../Common/plugins/Block_UI';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(1)
    }
}));

function WallPreview(props) {
    const classes = useStyles();
    const { fetchViewSettingsRequest } = props;
    useEffect(() => {
        const params = queryString.parse(window.location.search);
        if (params.clientId && params.artworkId) {
            fetchViewSettingsRequest({ clientId: params.clientId, artworkId: params.artworkId });
        }
    }, [fetchViewSettingsRequest]);
    return (
        <BlockUI dataLoading={props.loading || props.downloading}>
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12}>
                        {props.displayOverlay ? (
                            <div>
                                <OverlayContent />
                            </div>
                        ) : (
                            ''
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <HeaderContent />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={2}>
                            <PreviewContainer />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </BlockUI>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        displayOverlay: state.viewSettings.displayOverlay,
        loading: state.viewSettings.loading,
        frame: state.viewSettings.frame,
        downloading: state.viewSettings.downloading
    };
};

export default connect(mapStateToProps, { fetchViewSettingsRequest })(WallPreview);

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Divider from '@material-ui/core/Divider';
import _map from 'lodash/map';
import { connect } from 'react-redux';

import { setFrame } from '../WallPreview/viewSettingsAction';

const useStyles = makeStyles(theme => ({
    root: {
        width: '180px'
    },
    media: {
        height: '12px',
        paddingTop: '50.25%'
    },
    cardColor: {
        backgroundColor: '#C4C5CA'
    }
}));

const dataSource = [
    { frameUrl: '/assets/images/frame-none.svg', frameType: 'None' },
    { frameUrl: '/assets/images/frame-black.svg', frameType: 'Black' },
    { frameUrl: '/assets/images/frame-white.svg', frameType: 'White' },
    { frameUrl: '/assets/images/frame-darkwood.svg', frameType: 'Dark wood' },
    { frameUrl: '/assets/images/frame-silver.svg', frameType: 'Silver' },
    { frameUrl: '/assets/images/frame-blackwithmat.svg', frameType: 'Black with Mat' }
];

function Frames(props) {
    const classes = useStyles();
    const renderFrames = value => {
        props.setFrame(value);
    };

    return (
        <Grid container className={classes.root}>
            {_map(dataSource, (data, key) => {
                return (
                    <Grid item xs={12} key={key}>
                        <Card className={props.frameType === data.frameType ? classes.cardColor : null}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.media}
                                    image={data.frameUrl}
                                    title="No Frame"
                                    onClick={() => renderFrames(data.frameType)}
                                />
                                <Divider />
                                <CardActions>
                                    <div className="frameName">{data.frameType}</div>
                                </CardActions>
                            </CardActionArea>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        frameType: state.viewSettings.frame
    };
};

export default connect(mapStateToProps, { setFrame })(Frames);

import request from '../../service/request';

export const fetchViewSettingsService = ({ clientId, artworkId }) => {
    return request({
        url: `customers/${clientId}/artwork/${artworkId}`,
        method: 'get'
    })
        .then(response => response.data)
        .catch(({ data }) => ({ error: data }));
};

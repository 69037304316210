import React from 'react';
import { Typography } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';

import { renderOverlay } from '../../modules/WallPreview/viewSettingsAction';
import { keyNames } from '../Common/Components/General';

function OverlayContent(props) {
	const { viewSettinglist } = props;
	const handleCloseOverlay = () => {
		props.renderOverlay(false);
	};

	let tearSheetList = viewSettinglist.tearSheet;
	let tearSheetData = tearSheetList.dataFields.split(',');

	const priceGuide = () => {
		const { viewSettinglist } = props;
		if (viewSettinglist.price < 1000) {
			return 'red';
		} else if (viewSettinglist.price >= 1001 && viewSettinglist.price <= 5000) {
			return '#B11CD2';
		} else if (viewSettinglist.price >= 5001 && viewSettinglist.price <= 10000) {
			return 'blue';
		} else if (viewSettinglist.price >= 10001 && viewSettinglist.price <= 15000) {
			return '#1CBDCD';
		} else if (viewSettinglist.price >= 15001 && viewSettinglist.price <= 25000) {
			return '#17DF6F';
		} else if (viewSettinglist.price >= 25001 && viewSettinglist.price <= 50000) {
			return '#F2E93C';
		} else if (viewSettinglist.price >= 50001 && viewSettinglist.price <= 100000) {
			return '#615A5A';
		} else if (viewSettinglist.price >= 100001) {
			return '#160B04';
		}
	};
	return (
		<div className="overlay">
			<div className="closeBtn">
				<IconButton size="medium" color="secondary" onClick={handleCloseOverlay}>
					<CloseOutlinedIcon fontSize="large" />
				</IconButton>
			</div>
			<div id="overlay_Content">
				<Typography variant="h6" color="primary" className="mb-1">
					{viewSettinglist.artist.firstName} {viewSettinglist.artist.lastName}
				</Typography>
				<Typography variant="caption" color="primary" className="mt-1">
					{viewSettinglist.title}
					{viewSettinglist.subTitle.length ? `, ${viewSettinglist.subTitle}` : ''}
					{tearSheetData.includes('year') && viewSettinglist.year.length ? `, ${viewSettinglist.year}` : ''}
				</Typography>
				<div className="mt-3">
					<Typography variant="body2" color="primary" className="mt-1">
						{viewSettinglist.mediumType.length ? `${viewSettinglist.mediumType}` : ''}{' '}
						{tearSheetData.includes('media') && viewSettinglist.media.length ? (
							`- ${viewSettinglist.media}`
						) : (
							''
						)}
					</Typography>
					<Typography variant="body2" color="primary" className="mt-1">
						{tearSheetData.includes('dimensions') ? (
							`${viewSettinglist.slength} x ${viewSettinglist.swidth} ${viewSettinglist.customer
								.dimFormat}`
						) : (
							''
						)}
					</Typography>
					{tearSheetData.includes('location') && viewSettinglist.location.length ? (
						<Typography variant="body2" color="primary" className="mt-1">
							<label>
								<b>{keyNames.location}: </b>
							</label>{' '}
							{viewSettinglist.location}
						</Typography>
					) : (
						''
					)}
					{tearSheetData.includes('region') && viewSettinglist.location.length ? (
						<Typography variant="body2" color="primary" className="mt-1">
							<label>
								<b>{keyNames.region}: </b>
							</label>{' '}
							{viewSettinglist.location}
						</Typography>
					) : (
						''
					)}
					{tearSheetData.includes('edition') && viewSettinglist.edition.length ? (
						<Typography variant="body2" color="primary" className="mt-1">
							{viewSettinglist.edition}
						</Typography>
					) : (
						''
					)}
					<div className="mt-3">
						<Typography variant="body2" component={'span'} color="primary" className="mt-1">
							{tearSheetData.includes('retailprice') &&
							viewSettinglist.price >= 0 &&
							viewSettinglist.displayPrice === true &&
							viewSettinglist.inStock === true ? (
								`${viewSettinglist.customer.curFormat}${viewSettinglist.price}`
							) : viewSettinglist.callForPrice === true ? (
								`CALL FOR PRICE`
							) : viewSettinglist.priceGuide === true ? (
								<div className="colorDenotation" style={{ background: priceGuide() }} />
							) : viewSettinglist.inStock === false ? (
								<div className="stockDenotation" />
							) : (
								''
							)}{' '}
							<Typography variant="body2" color="primary" className="mt-1">
								{tearSheetData.includes('framed') && viewSettinglist.framed === true ? `framed` : ''}
							</Typography>
						</Typography>
					</div>
					<div className="mt-3">
						{tearSheetData.includes('catalognumber') && viewSettinglist.productItemNumber.length ? (
							<Typography variant="body2" color="primary" className="mt-1">
								<label>
									<b>{keyNames.catalognumber}:</b>
								</label>{' '}
								{viewSettinglist.productItemNumber}
							</Typography>
						) : (
							''
						)}
						{tearSheetData.includes('stocknumber') && viewSettinglist.productItemNumber.length ? (
							<Typography variant="body2" color="primary" className="mt-1">
								<label>
									<b>{keyNames.stocknumber}:</b>
								</label>{' '}
								{viewSettinglist.productItemNumber}
							</Typography>
						) : (
							''
						)}
						{tearSheetData.includes('description') && viewSettinglist.artDescription.length ? (
							<Typography
								variant="body2"
								color="primary"
								className="mt-1"
								dangerouslySetInnerHTML={{ __html: viewSettinglist.artDescription }}
							/>
						) : (
							''
						)}
						{tearSheetData.includes('provenance') && viewSettinglist.provenance.length ? (
							<Typography variant="body2" color="primary" className="mt-1">
								<label>
									<b>{keyNames.provenance}:</b>
								</label>{' '}
								{viewSettinglist.provenance}
							</Typography>
						) : (
							''
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		viewSettinglist: state.viewSettings.list
	};
};

export default connect(mapStateToProps, { renderOverlay })(OverlayContent);

import { call, put } from 'redux-saga/effects';

import {
    fetchViewSettingsSuccess,
    fetchViewSettingsFailure,
    setPieceWidth,
    setPieceHeight,
    setPieceDepth
} from '../viewSettingsAction';
import { fetchViewSettingsService } from '../viewSettingsService';

function* fetchViewSettings(action) {
    const { response, error } = yield call(fetchViewSettingsService, action.payload);
    if (response) {
        const {
            data: { artwork }
        } = response;
        yield put(fetchViewSettingsSuccess(artwork));
        yield put(setPieceWidth(artwork.swidth));
        yield put(setPieceHeight(artwork.slength));
        yield put(setPieceDepth(artwork));
    } else if (error) {
        yield put(fetchViewSettingsFailure());
    }
}

export default fetchViewSettings;

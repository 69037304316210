import {
    SET_WALL_COLOR,
    RENDER_OVERLAY,
    SET_PIECE_WIDTH,
    SET_PIECE_HEIGHT,
    SET_PIECE_DEPTH,
    SET_FRAME,
    FETCH_VIEW_SETTINGS_REQUEST,
    FETCH_VIEW_SETTINGS_SUCCESS,
    FETCH_VIEW_SETTINGS_FAILURE,
    PDF_LOADING
} from './viewSettingsConstants';

export const setWallColor = data => {
    return {
        type: SET_WALL_COLOR,
        payload: data
    };
};
export const renderOverlay = data => {
    return {
        type: RENDER_OVERLAY,
        payload: data
    };
};
export const setPieceWidth = data => {
    return {
        type: SET_PIECE_WIDTH,
        payload: data
    };
};
export const setPieceHeight = data => {
    return {
        type: SET_PIECE_HEIGHT,
        payload: data
    };
};
export const setPieceDepth = data => {
    return {
        type: SET_PIECE_DEPTH,
        payload: data
    };
};
export const setFrame = data => {
    return {
        type: SET_FRAME,
        payload: data
    };
};
export const fetchViewSettingsRequest = data => {
    return {
        type: FETCH_VIEW_SETTINGS_REQUEST,
        payload: data
    };
};
export const fetchViewSettingsSuccess = data => {
    return {
        type: FETCH_VIEW_SETTINGS_SUCCESS,
        payload: data
    };
};
export const fetchViewSettingsFailure = data => {
    return {
        type: FETCH_VIEW_SETTINGS_FAILURE,
        payload: data
    };
};
export const pdfLoading = data => {
    return {
        type: PDF_LOADING,
        payload: data
    };
};

import axios from 'axios';

const client = axios.create({ baseURL: 'https://api2.managedartwork.com/v2' });

const request = options => {
    return client(options)
        .then(response => response)
        .catch(error => Promise.reject(error.response || error.message));
};

export default request;

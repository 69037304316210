import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import Popover from '@material-ui/core/Popover';

function ColorPickerPopover(props) {
  const [background, setBackground] = useState('');
  const id = props.renderPicker ? 'simple-popover' : undefined;

  const handleClose = () => {
    props.handlePickerClose();
  };

  const onColorChangeComplete = (color) => {
    setBackground(color.hex);
    props.onColorChange(color.hex);
  };

  return (
    <div>
      <Popover
        id={id}
        open={props.renderPicker}
        anchorEl={props.renderPickerButton}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <SketchPicker color={background} disableAlpha={true} onChangeComplete={onColorChangeComplete} />
      </Popover>
    </div>
  );
}

export default ColorPickerPopover;

const initialSettings = {
    wallColor: '#F9F9F9',
    displayOverlay: false,
    pieceWidth: '0',
    pieceHeight: '0',
    pieceDepth: '0',
    dimensions:'0',
    frame: 'None',
    downloading:false,
    list: []
};

let initialState = {
    viewSettings: initialSettings
};

export default initialState;

import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles((theme) => ({
	root: (props) => ({
		backgroundColor: props.backgroundColor,
		height: '100vh'
	}),
	portrait: (props) => ({
		border: handleFrames(props),
		outline: handleBlackWithMatFrame(props)
	})
}));

const handleFrames = (props) => {
	const type = props.frameType;
	switch (type) {
		case 'Black':
			return '10px solid #000000';
		case 'Silver':
			return '10px solid #A3A3A3';
		case 'White':
			return '10px solid white';
		case 'Dark wood':
			return '10px solid #42200d';
		case 'Black with Mat':
			return '6px solid white';
		default:
			return null;
	}
};

const handleBlackWithMatFrame = (props) => {
	if (props.frameType === 'Black with Mat') {
		return '10px solid black';
	}
};

const imageDimFormat = (viewSettinglist, dimension) => {
	if (viewSettinglist.customer.dimFormat === 'inches') {
		return dimension;
	} else if (viewSettinglist.customer.dimFormat === 'cm') {
		return dimension / 2.54;
	} else if (viewSettinglist.customer.dimFormat === 'mm') {
		return dimension / 25.4;
	}
};

function PreviewContainer(props) {
	const classes = useStyles(props);
	const [ styles, setStyles ] = useState({});
	const { viewSettinglist } = props;
	let windowWidth = window.innerWidth;
	let windowHeight = window.innerHeight;
	var dpi = 120;
	if (window.devicePixelRatio < 2) {
		dpi = 120;
	} else if (window.devicePixelRatio === 2 && windowWidth > 1600) {
		dpi = 60 * window.devicePixelRatio;
	} else if ((window.devicePixelRatio === 2 || window.devicePixelRatio === 4) && windowWidth <= 576) {
		dpi = 120 * window.devicePixelRatio;
	} else if (window.devicePixelRatio === 2 && windowWidth <= 768) {
		dpi = 96 * window.devicePixelRatio;
	} else if (window.devicePixelRatio === 3 && windowWidth <= 576) {
		dpi = 72 * window.devicePixelRatio;
	}

	const canvasImage = useCallback(
		(url, ctx) => {
			if (url) {
				var img = new Image();
				img.src = url;
				img.onload = function(e) {
					let imgWidth = '';
					let imgHeight = '';
					let originWidth = window.innerWidth;
					let originHeight = window.innerHeight;
					if (!_isEmpty(viewSettinglist)) {
						let widthInInch = imageDimFormat(viewSettinglist, viewSettinglist.swidth);
						let heightInInch = imageDimFormat(viewSettinglist, viewSettinglist.slength);
						imgWidth = widthInInch * (originWidth / dpi);
						imgHeight = heightInInch * (originHeight / dpi);
						if (originWidth > originHeight) {
							imgWidth = widthInInch * (originHeight / dpi);
							imgHeight = heightInInch * (originHeight / dpi);
						} else {
							imgWidth = widthInInch * (originWidth / dpi);
							imgHeight = heightInInch * (originWidth / dpi);
						}
					}
					var ctxWidth = ctx.canvas.width,
						ctxHeight = ctx.canvas.height;
					var ratioWidth = imgWidth / ctxWidth,
						ratioHeight = imgHeight / ctxHeight,
						ratioAspect = ratioWidth > 1 ? ratioWidth : ratioHeight > 1 ? ratioHeight : 1;
					var newWidth = imgWidth / ratioAspect,
						newHeight = imgHeight / ratioAspect;
					setStyles({ width: newWidth, height: newHeight });
				};
			}
		},
		[ viewSettinglist, dpi ]
	);

	useEffect(() => {
		if (props.viewSettinglist.image) {
			window.addEventListener(
				'resize',
				() => {
					var ctx = document.getElementById('image-viewer').getContext('2d');
					canvasImage(props.viewSettinglist.image, ctx);
				},
				[ props.viewSettinglist.image ]
			);
		}
	});

	useEffect(() => {
		return () => {
			window.removeEventListener('resize', () => {});
		};
	}, []);

	useEffect(
		() => {
			var ctx = document.getElementById('image-viewer').getContext('2d');
			canvasImage(props.viewSettinglist.image, ctx);
		},
		[ props.pieceWidth, props.pieceHeight, props.viewSettinglist.image, canvasImage ]
	);

	return (
		<Grid container className={classes.root} id="wall">
			<div className="portraitWrapper" id="artworks">
				{props.frameType === 'Black with Mat' ? (
					<img
						src={viewSettinglist.image}
						style={styles}
						alt=""
						className={`matFrame_shadow ${classes.portrait}`}
					/>
				) : (
					<img
						src={viewSettinglist.image}
						style={styles}
						alt=""
						className={`portrait_shadow ${classes.portrait}`}
					/>
				)}
				<canvas id="image-viewer" width={windowWidth} height={windowHeight} style={{ display: 'none' }} />
			</div>
			<div className="CouchWrapper" id="couch">
				<img src="/assets/images/couch.png" alt="couch" className="couchDimension" />
			</div>
		</Grid>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		backgroundColor: state.viewSettings.wallColor,
		viewSettinglist: state.viewSettings.list,
		frameType: state.viewSettings.frame,
		pieceWidth: state.viewSettings.pieceWidth,
		pieceHeight: state.viewSettings.pieceHeight
	};
};

export default connect(mapStateToProps, null)(PreviewContainer);

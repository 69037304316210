import {
    SET_WALL_COLOR,
    RENDER_OVERLAY,
    SET_PIECE_WIDTH,
    SET_PIECE_HEIGHT,
    SET_PIECE_DEPTH,
    SET_FRAME,
    FETCH_VIEW_SETTINGS_REQUEST,
    FETCH_VIEW_SETTINGS_SUCCESS,
    FETCH_VIEW_SETTINGS_FAILURE,
    PDF_LOADING
} from './viewSettingsConstants';

function viewSettings(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_WALL_COLOR:
            return {
                ...state,
                wallColor: payload
            };
        case RENDER_OVERLAY:
            return {
                ...state,
                displayOverlay: payload
            };
        case SET_PIECE_WIDTH:
            return {
                ...state,
                pieceWidth: payload
            };
        case SET_PIECE_HEIGHT:
            return {
                ...state,
                pieceHeight: payload
            };
        case SET_PIECE_DEPTH:
            return {
                ...state,
                pieceDepth: payload.depth
            };
        case SET_FRAME:
            return {
                ...state,
                frame: payload
            };
        case FETCH_VIEW_SETTINGS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_VIEW_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                list: payload
            };
        case FETCH_VIEW_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false
            };
        case PDF_LOADING:
            return {
                ...state,
                downloading: payload
            };
        default:
            return state;
    }
}

export default viewSettings;

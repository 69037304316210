import React from 'react';
import Grid from '@material-ui/core/Grid';

import HeaderComponents from '../WallPreview/HeaderComponents';

function HeaderContent() {
    return (
        <Grid container justify="space-between" alignItems="center">
            <Grid item>
                <b>View on a wall</b>
            </Grid>
            <Grid item>
                <HeaderComponents />
            </Grid>
        </Grid>
    );
}

export default HeaderContent;

import React from 'react';
import './sass/App.scss';
import { Provider } from 'react-redux';
import store from '../src/store';
import WallPreview from './modules/WallPreview/WallPreview';
import { MuiThemeProvider } from '@material-ui/core/styles';
import muiTheme from './muiTheme';

const theme = muiTheme;

function App() {
    return (
        <MuiThemeProvider theme={theme}>
            <Provider store={store}>
                <WallPreview />
            </Provider>
        </MuiThemeProvider>
    );
}

export default App;

import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import BorderStyleIcon from '@material-ui/icons/BorderStyle';
import ColorizeOutlinedIcon from '@material-ui/icons/ColorizeOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Drawer from '@material-ui/core/Drawer';
import slugify from 'slugify';
import TagManager from 'react-gtm-module';

import ColorPickerPopover from '../Common/Components/ColorPickerComponent';
import { setWallColor, renderOverlay, pdfLoading } from '../../modules/WallPreview/viewSettingsAction';
import Frames from '../WallPreview/FramesComponent';
import { keyNames } from '../Common/Components/General';

const tagManagerArgs = {
	gtmId: 'GTM-5PTVQLS'
};
TagManager.initialize(tagManagerArgs);

const setBorders = (frameType) => {
	var type = frameType;
	switch (type) {
		case 'Black':
			return '8px solid #000000';
		case 'Silver':
			return '8px solid #A3A3A3';
		case 'White':
			return '8px solid white';
		case 'Dark wood':
			return '8px solid #42200d';
		case 'Black with Mat':
			return '8px solid black';
		default:
			return null;
	}
};

function HeaderComponents(props) {
	const [ openDirection, setOpenDirection ] = useState({ right: false });
	const [ renderPicker, setRenderPicker ] = useState(false);
	const [ renderPickerButton, setRenderPickerButton ] = useState('');
	const borders = setBorders(props.frameType);

	const renderSketchPicker = (event) => {
		setRenderPicker(true);
		setRenderPickerButton(event.currentTarget);
		TagManager.dataLayer({
			dataLayer: {
				event: 'Wall-Color'
			}
		});
	};

	const handlePickerClose = () => {
		setRenderPicker(false);
	};
	const onColorChange = (wallColor) => {
		props.setWallColor(wallColor);
	};

	const renderInfoOverlay = () => {
		props.renderOverlay(true);
		TagManager.dataLayer({
			dataLayer: {
				event: 'Info-Overlay'
			}
		});
	};

	const toggleDrawer = (anchor, open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setOpenDirection({ ...openDirection, [anchor]: open });
		TagManager.dataLayer({
			dataLayer: {
				event: 'Frames-Component'
			}
		});
	};

	const toDataURL = (url) =>
		fetch(url).then((response) => response.blob()).then(
			(blob) =>
				new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onloadend = () => resolve(reader.result);
					reader.onerror = reject;
					reader.readAsDataURL(blob);
				})
		);

	const closeModal = () => {
		window.parent.postMessage('closeModal', '*');
	};

	const slug = slugify(`${props.viewSettinglist.title}-${props.viewSettinglist.newId}`, {
		lower: true
	});

	const calculateWidthRatio = (dimensions) => {
		let windowWidth = 1680;
		let wallWidth = 1168;
		let widthRatio = windowWidth / wallWidth;
		return dimensions / widthRatio;
	};

	const imageDimFormat = (viewSettinglist, dimension) => {
		if (viewSettinglist.customer.dimFormat === 'inches') {
			return dimension;
		} else if (viewSettinglist.customer.dimFormat === 'cm') {
			return dimension / 2.54;
		} else if (viewSettinglist.customer.dimFormat === 'mm') {
			return dimension / 25.4;
		}
	};
	const priceGuide = () => {
		const { viewSettinglist } = props;
		if (viewSettinglist.price < 1000) {
			return 'red';
		} else if (viewSettinglist.price >= 1001 && viewSettinglist.price <= 5000) {
			return '#B11CD2';
		} else if (viewSettinglist.price >= 5001 && viewSettinglist.price <= 10000) {
			return 'blue';
		} else if (viewSettinglist.price >= 10001 && viewSettinglist.price <= 15000) {
			return '#1CBDCD';
		} else if (viewSettinglist.price >= 15001 && viewSettinglist.price <= 25000) {
			return '#17DF6F';
		} else if (viewSettinglist.price >= 25001 && viewSettinglist.price <= 50000) {
			return '#F2E93C';
		} else if (viewSettinglist.price >= 50001 && viewSettinglist.price <= 100000) {
			return '#615A5A';
		} else if (viewSettinglist.price >= 100001) {
			return '#160B04';
		}
	};

	const generatePdf = async () => {
		props.pdfLoading(true);
		try {
			const { viewSettinglist } = props;
			const priceColor = priceGuide();
			let widthInInch = imageDimFormat(viewSettinglist, viewSettinglist.swidth);
			let heightInInch = imageDimFormat(viewSettinglist, viewSettinglist.slength);
			let windowHeight = 650;
			const dpi = 120;
			let Width = widthInInch * (windowHeight / dpi);
			let Height = heightInInch * (windowHeight / dpi);
			let imgWidth = calculateWidthRatio(Width);
			let imgHeight = calculateWidthRatio(Height);
			const queryString = new Date().getTime();
			const base64 = await toDataURL(`${viewSettinglist.customer.headerLogo}?version=${queryString}`);
			const portraitBase64 = await toDataURL(`${viewSettinglist.image}?version=${queryString}`);
			var viewContent = document.getElementById('viewportMeta').getAttribute('content');
			document.getElementById('viewportMeta').setAttribute('content', 'width=1200');
			let tearSheetList = viewSettinglist.tearSheet;
			let tearSheetData = tearSheetList.dataFields.split(',');

			var html = `<div>
                        <div style='text-align:center';>
                         <img alt="no logo" src="${base64}" height="45" />
                        </div>
                        <div style='margin-top:20px; width:830px; height:445px;display:flex; flex-direction:column; background-color:${props.backgroundColor};'>
                        <div style='text-align: center;position: relative;top: 170px;transform: translate(0%, -50%);'>
                        ${props.frameType === 'Black with Mat'
							? `<img
                                    src="${portraitBase64}"
                                    style="width:${imgWidth}px; height:${imgHeight}px; border:${borders}; padding:6px; background:white"
                                ></img>`
							: `<img
                                    src="${portraitBase64}"
                                    style="width:${imgWidth}px; height:${imgHeight}px; border:${borders};"
                                ></img>`}
                       
                        </div>
                        <div style='position:fixed;top:410px;text-align: center;'>
                        <img alt="no logo"  src="${'/assets/images/couch.png'}" height="170px" width="830px" />
                        </div>
                        </div>
                        <div style='text-align: left; width:50%; margin:40px 0px 0px 90px;height:400px;'>
                         <h3 style='margin-bottom:-4px;'>
                            ${viewSettinglist.artist.firstName} ${viewSettinglist.artist.lastName}
                        </h3>
                         <i>
                            ${viewSettinglist.title}${viewSettinglist.subTitle.length
				? `, ${viewSettinglist.subTitle}`
				: ''} ${tearSheetData.includes('year') && viewSettinglist.year.length
				? `, ${viewSettinglist.year}`
				: ''}
                       
                        </i>
                        <div style='margin-top: 1.5rem;'>
                        <p style='margin-bottom:-0.5rem;'>
                         ${viewSettinglist.mediumType.length
								? `${viewSettinglist.mediumType}`
								: ''}  ${tearSheetData.includes('media') && viewSettinglist.media.length
				? `- ${viewSettinglist.media}`
				: ''}
                        </p>
                        <p style='margin-bottom:-0.5rem;'>
                        ${tearSheetData.includes('dimensions')
							? `${viewSettinglist.slength} x ${viewSettinglist.swidth} ${viewSettinglist.customer
									.dimFormat}`
							: ''}
                        </p>
                        ${tearSheetData.includes('location') && viewSettinglist.location.length
							? `<p style='margin-bottom:-0.5rem;'>
                                    <label><b>${keyNames.location}:</b></label>
                                    ${viewSettinglist.location}
                                </p>`
							: ''}
                         ${tearSheetData.includes('region') && viewSettinglist.location.length
								? `<p style='margin-bottom:-0.5rem;'>
                                      <label><b>${keyNames.region}:</b></label>
                                     ${viewSettinglist.location}
                                 </p>`
								: ''}
                     <p>
                        ${tearSheetData.includes('edition') && viewSettinglist.edition.length
							? `${viewSettinglist.edition}`
							: ''}
                    </p>
                        </div>
                        <div style='margin-top:2rem;'>
                        <p style='margin-bottom:-0.5rem;'>
                            ${tearSheetData.includes('retailprice') &&
							viewSettinglist.price >= 0 &&
							viewSettinglist.displayPrice === true &&
							viewSettinglist.inStock === true
								? `${viewSettinglist.customer.curFormat}${viewSettinglist.price}`
								: viewSettinglist.callForPrice === true
									? `CALL FOR PRICE`
									: viewSettinglist.priceGuide === true
										? `<div style='box-sizing: border-box; width:10px; height:10px; border: 1px solid black;background-color:${priceColor};'></div>`
										: viewSettinglist.inStock === false
											? `<div style="height: 10px;width: 10px;background-color:red;border-radius: 50%;"></div>`
											: ''}
                            <p>${tearSheetData.includes('framed') && viewSettinglist.framed === true
								? `framed`
								: ''}</p>
                        </p>
                        </div>
                        <div style='margin-top:1.5rem;'>
                        ${tearSheetData.includes('catalognumber') && viewSettinglist.productItemNumber.length
							? `<p style='margin-bottom:-0.5rem;'>
                                <label>
                                    <b>${keyNames.catalognumber}:</b>
                                </label> ${viewSettinglist.productItemNumber}
                            </p>`
							: ''}
                        ${tearSheetData.includes('stocknumber') && viewSettinglist.productItemNumber.length
							? `<p style='margin-bottom:-0.5rem;'>
                                <label>
                                    <b>${keyNames.stocknumber}:</b>
                                </label> ${viewSettinglist.productItemNumber}
                            </p>`
							: ''}
                        ${tearSheetData.includes('description') && viewSettinglist.artDescription.length
							? `<p style='margin-bottom:-0.5rem;'
                            > ${viewSettinglist.artDescription}</p>`
							: ''}
                        ${tearSheetData.includes('provenance') && viewSettinglist.provenance.length
							? `<p>
                                <label>
                                    <b>${keyNames.provenance}:</b>
                                </label>
                               ${viewSettinglist.provenance}
                            </p>`
							: ''}
                    </div>
                        </div>
                        <div style='margin-left:350px;width:20%; margin-top:170px;'>
                        <p>${viewSettinglist.customer.footer}</p>
                        </div>
                        </div>`;
			var tempDiv = document.createElement('div');
			tempDiv.innerHTML = html;
			document.getElementById('clone').appendChild(tempDiv.firstChild);
			html2canvas(document.getElementById('clone'), {
				scale: 1
			}).then((canvas) => {
				const imgData = canvas.toDataURL('image/png');
				var doc = new jsPDF();
				var imgWidth = canvas.width * 50 / 210;
				var imgHeight = canvas.height * imgWidth / canvas.width;
				doc.addImage(imgData, 'PNG', 6, 5, imgWidth, imgHeight);
				doc.save(`${slug}`);
				props.pdfLoading(false);
			});
			document.getElementById('viewportMeta').setAttribute('content', viewContent);
			document.getElementById('clone').innerHTML = '';
		} catch (err) {
			props.pdfLoading(false);
		}
		TagManager.dataLayer({
			dataLayer: {
				event: 'File-Download'
			}
		});
	};

	return (
		<>
			<Grid container spacing={1}>
				<Grid item>
					<IconButton size="small" color="primary" onClick={renderInfoOverlay}>
						<InfoOutlinedIcon />
					</IconButton>
				</Grid>
				<Grid item>
					<IconButton size="small" color="primary" onClick={toggleDrawer('right', true)}>
						<BorderStyleIcon />
						<Hidden xsDown>Frames</Hidden>
					</IconButton>
					<Drawer anchor={'right'} open={openDirection['right']} onClose={toggleDrawer('right', false)}>
						<Frames />
					</Drawer>
				</Grid>
				<Grid item>
					<IconButton size="small" color="primary" onClick={renderSketchPicker}>
						<ColorizeOutlinedIcon />
						<Hidden xsDown> Wall color</Hidden>
					</IconButton>
					<ColorPickerPopover
						handlePickerClose={handlePickerClose}
						onColorChange={onColorChange}
						renderPicker={renderPicker}
						renderPickerButton={renderPickerButton}
					/>
				</Grid>
				<Grid item>
					<IconButton size="small" color="primary" onClick={generatePdf}>
						<SaveAltOutlinedIcon />
						<Hidden xsDown>Download</Hidden>
					</IconButton>
				</Grid>
				<Grid item>
					<IconButton size="small" color="primary" onClick={closeModal}>
						<CloseOutlinedIcon />
					</IconButton>
				</Grid>
			</Grid>
			<div>
				<div id="clone" />
			</div>
		</>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		viewSettinglist: state.viewSettings.list,
		backgroundColor: state.viewSettings.wallColor,
		frameType: state.viewSettings.frame
	};
};

export default connect(mapStateToProps, { setWallColor, renderOverlay, pdfLoading })(HeaderComponents);

export const keyNames = {
    mediumtype: 'Medium Type',
    catalognumber: 'Catalog number',
    framed: 'Framed',
    description: 'Description',
    media: 'Media',
    year: 'Year',
    dimensions: 'Dimensions',
    edition: 'Edition',
    location: 'Location',
    region: 'Region',
    stocknumber: 'Stock number',
    retailprice: 'Retail price',
    provenance: 'Provenance'
};

